<template>
  <b-container fluid class="px-0">
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>
    <!-- Queue -->
    <b-card v-if="!full_screen">
      <b-row class="mx-0">
        <b-col cols="6">
          <span>{{ lang("t_queue") }}</span>
          <v-select
            class="mt-50"
            v-model="selected_queues"
            :reduce="(option) => option.internal_name"
            label="display_name"
            value="internal_name"
            :options="queues"
            :placeholder="lang('t_queue')"
            multiple
            :closeOnSelect="false"
            @input="
              () => {
                get_live_agent();
                get_live_queue();
              }
            "
          >
            <template #list-header>
              <div class="w-100 mb-50">
                <b-button
                  class="w-50"
                  size="sm"
                  variant="outline-primary"
                  @click="
                    () => {
                      selected_queues = queues.map((e) => e.internal_name);
                      get_live_agent();
                      get_live_queue();
                    }
                  "
                >
                  <feather-icon size="14" icon="CheckCircleIcon" />
                  {{ lang("t_selectAll") }}
                </b-button>
                <b-button
                  class="w-50"
                  size="sm"
                  variant="outline-danger"
                  @click="
                    () => {
                      selected_queues = [];
                      get_live_agent();
                      get_live_queue();
                    }
                  "
                >
                  <feather-icon size="14" icon="XOctagonIcon" />
                  {{ lang("t_cleanSelections") }}
                </b-button>
              </div>
            </template>
          </v-select>
        </b-col>
        <b-col cols="6">
          <span>{{ lang("t_queueGroup") }}</span>
          <v-select
            class="mt-50"
            v-model="selected_queue_group"
            :reduce="(option) => option"
            label="display_name"
            :options="queue_groups"
            :placeholder="lang('t_queueGroup')"
            multiple
            :closeOnSelect="false"
            @input="queue_group_changed"
          >
          </v-select>
        </b-col>
      </b-row>
    </b-card>

    <!-- Revenue Comparison Line -->

    <!-- <b-card-actions
      v-if="!full_screen"
      :collapsed="true"
      :title="lang('t_hourlyGraphic')"
      action-collapse
    >
      <b-row>
        <b-col cols="12">
          <b-card-body class="pb-0">
            <vue-apex-charts
              type="line"
              height="240"
              :options="revenueComparisonLine.chartOptions"
              :series="revenueComparisonLine.series"
              ref="lineChart"
              :key="revenueComparisonLine.series.length"
            />
          </b-card-body>
        </b-col>
      </b-row>
    </b-card-actions> -->

    <!-- Statistics -->
    <b-container v-if="!full_screen" fluid class="px-0 mt-2">
      <b-row>
        <b-col
          @click="get_interaction_details(item.internal_name)"
          v-for="(item, i) in top_bar_info"
          :key="i"
          md="2"
          sm="6"
          :class="item.customClass"
        >
          <b-card
            :border-variant="item.color.replace('light-', '')"
            :title="item ? item.subtitle : ''"
          >
            <b-media no-body>
              <b-media-aside class="pr-2">
                <b-avatar size="48" :variant="item.color">
                  <feather-icon size="24" :icon="item.icon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h3 class="font-weight-bolder mb-0">
                  {{ item.content }}
                </h3>
              </b-media-body>
            </b-media>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <b-card no-body>
      <b-row class="mt-1 mx-0">
        <b-col cols="12" class="px-0">
          <b-row class="px-1 mx-0">
            <b-col class="">
              <b-button-group>
                <b-button
                  v-b-modal.modal-announce
                  v-b-tooltip.hover.v-primary
                  v-b-tooltip.hover.bottom="lang('t_announcement')"
                  variant="outline-primary"
                >
                  <feather-icon icon="Volume2Icon" />
                </b-button>
                <b-button
                  v-b-modal.modal-status-change
                  v-b-tooltip.hover.v-primary
                  v-b-tooltip.hover.bottom="lang('t_changeAllStatuses')"
                  variant="outline-primary"
                >
                  <feather-icon icon="UserCheckIcon" />
                </b-button>
                <b-button
                  v-b-modal.modal-queue-change
                  v-b-tooltip.hover.v-primary
                  v-b-tooltip.hover.bottom="lang('t_changeAllQueues')"
                  variant="outline-primary"
                >
                  <feather-icon icon="ShuffleIcon" />
                </b-button>
                <b-button
                  @click="
                    () => {
                      dont_show_go_home_status = !dont_show_go_home_status;
                      get_live_agent();
                    }
                  "
                  v-b-tooltip.hover.v-primary
                  v-b-tooltip.hover.bottom="
                    dont_show_go_home_status
                      ? lang('t_hideWentHome')
                      : lang('t_showWentHome')
                  "
                  variant="outline-primary"
                >
                  <feather-icon
                    :icon="dont_show_go_home_status ? 'EyeOffIcon' : 'EyeIcon'"
                  />
                </b-button>

                <!-- <b-button
                    v-b-tooltip.hover.v-primary
                    v-b-tooltip.hover.bottom="'Dashboard Kontol'"
                    variant="outline-primary"
                  >
                    <feather-icon icon="PieChartIcon" />
                  </b-button> -->
              </b-button-group>
            </b-col>
            <b-col>
              <b-button-group class="float-right w-100">
                <b-form-input
                  v-model="searchQuery"
                  :placeholder="lang('t_search')"
                />
                <b-button
                  v-b-tooltip.hover.v-primary
                  v-b-tooltip.hover.bottom="
                    full_screen ? lang('t_shrink') : lang('t_expand')
                  "
                  class=""
                  variant="flat-primary"
                  @click="full_screen = !full_screen"
                >
                  <feather-icon
                    :icon="!full_screen ? 'Maximize2Icon' : 'Minimize2Icon'"
                  ></feather-icon>
                </b-button>
              </b-button-group>
            </b-col>
          </b-row>
          <b-row class="mt-1 mx-0 px-0">
            <b-table
              small
              @row-dblclicked="showDetails"
              :items="agents.filter((e) => e.visible_in_reports == true)"
              :fields="fields"
              striped
              responsive="sm"
              :filter="searchQuery"
              :filter-included-fields="[]"
              class="mb-0 cursor-pointer w-100"
            >
              <template #row-details="">
                <b-card>
                  <b-row class="mb-2">
                    <b-col md="6">
                      <v-select
                        v-model="filter_values.selectedQueues"
                        :options="queues"
                        :placeholder="lang('t_queue')"
                        multiple
                      >
                      </v-select>
                    </b-col>
                    <b-col md="6">
                      <v-select
                        v-model="filter_values.selectedQueues2"
                        :options="spv_statuses"
                        :placeholder="lang('t_status')"
                        multiple
                      >
                      </v-select>
                    </b-col>
                  </b-row>
                </b-card>
              </template>
              <template #cell(BreakTime)="data">
                <b-button-group>
                  <span> {{ data.value }}</span>
                  <feather-icon
                    size="20"
                    v-if="data.item.BreakTimeFraud"
                    class="text-danger ml-1"
                    icon="AlertOctagonIcon"
                  ></feather-icon>
                </b-button-group>
              </template>

              <!-- <template #cell(avatar)="data">
                <b-avatar
                style=""
                  class="badge-glow"
                  :variant="
                    colors[
                      data.item.Status == 'Available'
                        ? 'Available'
                        : data.item.Reason
                    ]
                  "
                >
                  {{ data.item.Username.split(".")[0][0].toUpperCase() }}
                </b-avatar>
              </template> -->

              <template #cell(Reason)="data">
                <b-badge
                  class="badge-glow my-25"
                  style="width: 11vh"
                  :variant="colors[data.value]"
                >
                  {{ data.value }}
                </b-badge>
              </template>
              <template #cell(time)="data">
                {{ timer(data.value) }}
              </template>
              <template #cell(Queue)="data">
                {{
                  data.value == ""
                    ? ""
                    : queues.find((e) => e.internal_name == data.value) !=
                      undefined
                    ? queues
                        .find((e) => e.internal_name == data.value)
                        .display_name.replace(/ /g, "\u00a0") +
                      " / " +
                      (data.item.Classification
                        ? data.item.Classification.replace(/ /g, "\u00a0")
                        : data.item.Classification)
                    : data.value + " / " + data.item.Classification
                }}
              </template>
            </b-table>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      size="lg"
      static
      no-close-on-esc
      no-close-on-backdrop
      :title="selected_agent ? selected_agent.Username : ''"
      hide-footer
      centered
      v-model="modal_agent_details"
    >
      <b-tabs>
        <b-tab title="Özet">
          <b-row
            v-if="selected_agent.hasOwnProperty('details')"
            class="border-bottom py-1"
          >
            <b-col class="border-right">
              <center>
                <b class="text-success">{{ lang("t_answered") }}</b>
              </center>
              <center class="text-success">
                {{ selected_agent.details.inbound_success }}
              </center>
            </b-col>
            <b-col class="border-right">
              <center>
                <b class="text-danger">{{ lang("t_abandonedCount") }}</b>
              </center>
              <center class="text-danger">
                {{ selected_agent.details.inbound_failed }}
              </center>
            </b-col>
            <b-col class="border-right">
              <center>
                <b class="text-primary">{{ lang("t_productivity") }}</b>
              </center>
              <center class="text-primary">
                %{{ selected_agent.details.productivity }}
              </center>
            </b-col>
            <b-col class="border-right">
              <center>
                <b class="text-success">{{ lang("t_successfullCall") }}</b>
              </center>
              <center class="text-success">
                {{ selected_agent.details.outbound_success }}
              </center>
            </b-col>
            <b-col>
              <center>
                <b class="text-danger">{{ lang("t_unsuccessfullCall") }}</b>
              </center>
              <center class="text-danger">
                {{ selected_agent.details.outbound_failed }}
              </center>
            </b-col>
          </b-row>
          <b-row
            v-if="selected_agent.hasOwnProperty('details')"
            class="border-bottom py-1"
          >
            <b-col class="border-right">
              <center>
                <b>{{ lang("t_usedBreakTime") }}</b>
              </center>
              <center>{{ selected_agent.details.break_time }}</center>
            </b-col>
            <b-col
              v-if="selected_agent.details.break_time_fraud"
              class="border-right"
            >
              <center>
                <b class="text-danger">{{ lang("t_reakTimeFraud") }}</b>
                <center>
                  <feather-icon
                    class="text-danger"
                    icon="AlertOctagonIcon"
                  ></feather-icon>
                </center>
              </center>
            </b-col>
            <b-col>
              <center>
                <b>{{ lang("t_remainingBreakTime") }}</b>
              </center>
              <center>{{ selected_agent.details.remaining_break_time }}</center>
            </b-col>
          </b-row>
          <b-row v-if="selected_agent.hasOwnProperty('details')" class="py-1">
            <b-table
              style="max-height: 26vh; overflow-y: auto"
              small
              :fields="fc_fields"
              :items="selected_agent.details.fc_data"
              responsive="sm"
              fixed
            >
              <!-- A custom formatted column -->
              <template #cell(rate)="data">
                <center>
                  <b-progress
                    show-value
                    :value="data.value"
                    :max="selected_agent.details.total_finish_code_counts"
                    :variant="
                      parseInt(
                        (data.value /
                          selected_agent.details.total_finish_code_counts) *
                          100
                      ) > 40
                        ? 'success'
                        : 'warning'
                    "
                    striped
                  />
                </center>
              </template>

              <template #cell(category)="data">
                <center>
                  <b-badge
                    style="width: 70%"
                    pill
                    :variant="fc_category_colors[data.value]"
                  >
                    {{ data.value }}
                  </b-badge>
                </center>
              </template>
            </b-table>
          </b-row>
          <b-row class="mt-1">
            <v-select
              :disabled="
                ['RingingOut', 'InInteraction', 'AgentRinging '].includes(
                  selected_agent.Status
                )
              "
              class="w-100"
              v-model="filter_values.selectedQueues"
              :options="all_queues"
              :reduce="(val) => val.internal_name"
              label="display_name"
              :placeholder="lang('t_queue')"
              multiple
              @option:selected="add_queue"
              @option:deselected="remove_queue"
            >
            </v-select>
          </b-row>
          <b-row class="mt-1">
            <v-select
              :disabled="
                ['RingingOut', 'InInteraction', 'AgentRinging '].includes(
                  selected_agent.Status
                )
              "
              class="w-100"
              v-model="current_reason"
              :options="spv_statuses"
              label="display_name"
              :reduce="(val) => val.display_name"
              :placeholder="lang('t_status')"
              @input="change_user_state"
              :clearable="false"
              :closeOnSelect="false"
            >
            </v-select>
          </b-row>
          <b-row class="mt-1" align-h="center">
            <b-col cols="8" class="text-center">
              <b-button
                :disabled="
                  !['RingingOut', 'InInteraction', 'AgentRinging '].includes(
                    selected_agent.Status
                  )
                "
                @click="chanspy('listen')"
                v-b-tooltip.hover.v-primary
                v-b-tooltip.hover.bottom="lang('t_listen')"
                variant="outline-primary"
                class="btn-icon rounded-circle mx-1"
              >
                <feather-icon icon="HeadphonesIcon" />
              </b-button>

              <b-button
                :disabled="
                  !['RingingOut', 'InInteraction', 'AgentRinging '].includes(
                    selected_agent.Status
                  )
                "
                @click="chanspy('souffle')"
                v-b-tooltip.hover.v-primary
                v-b-tooltip.hover.bottom="lang('t_souffle')"
                variant="outline-primary"
                class="btn-icon rounded-circle mx-1"
              >
                <feather-icon icon="BellIcon" />
              </b-button>

              <b-button
                :disabled="
                  !['RingingOut', 'InInteraction', 'AgentRinging '].includes(
                    selected_agent.Status
                  )
                "
                @click="chanspy('join')"
                v-b-tooltip.hover.v-primary
                v-b-tooltip.hover.bottom="lang('t_conference')"
                variant="outline-primary"
                class="btn-icon rounded-circle mx-1"
              >
                <feather-icon icon="UsersIcon" />
              </b-button>
            </b-col>
            <b-col class="border-left text-center">
              <b-button
                v-b-modal.modal-sufle
                v-b-tooltip.hover.v-primary
                v-b-tooltip.hover.bottom="lang('t_writtenSouffle')"
                variant="outline-primary"
                class="btn-icon rounded-circle mx-1"
              >
                <feather-icon icon="MessageSquareIcon" />
              </b-button>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Chat">
          <chat></chat>
        </b-tab>
      </b-tabs>
    </b-modal>
    <b-modal
      static
      size="xl"
      no-close-on-esc
      no-close-on-backdrop
      :title="
        selected_interaction_type == 'abandoned_interactions'
          ? lang('t_mrAbandonedCalls')
          : lang('t_waitingInQueue')
      "
      hide-footer
      centered
      v-model="modal_interaction_details"
      @hide="set_abandon_call_fields"
      @close="set_abandon_call_fields"
    >
      <b-table
        :busy="isBusy"
        :items="interaction_details_data"
        :fields="interaction_details_fields"
        striped
        responsive="sm"
        style="max-height: 60vh; overflow-y: auto"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Yükleniyor...</strong>
          </div>
        </template>
        <template #cell(is_reached)="data">
          <feather-icon
            size="20"
            :icon="data.value ? 'CheckCircleIcon' : 'XCircleIcon'"
          />
        </template>
        <template #cell(queue)="data">
          {{
            queues.find((e) => e.internal_name == data.item.queue) != undefined
              ? queues.find((e) => e.internal_name == data.item.queue)
                  .display_name
              : data.item.queue
          }}
        </template>
      </b-table>
      <b-button
        v-if="selected_interaction_type == 'abandoned_interactions'"
        @click="onexport"
        class="float-right"
        variant="success"
        >{{ lang("t_export") }}
      </b-button>
      <b-button
        v-if="selected_interaction_type == 'abandoned_interactions'"
        @click="abandon_call_details"
        class="float-right mr-1"
        variant="primary"
        >{{ lang("t_showCalledBack") }}
      </b-button>
    </b-modal>
    <b-modal
      static
      id="modal-sufle"
      cancel-variant="outline-secondary"
      :ok-title="lang('t_send')"
      centered
      ok-only
      :title="lang('t_writtenSouffle')"
      @ok="sendSouffle"
    >
      <b-form-group>
        <label for="message">{{ lang("t_text") }}</label>
        <b-form-input
          v-model="souffle_message"
          id="message"
          :placeholder="lang('t_yourMessage')"
        />
      </b-form-group>
    </b-modal>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      id="modal-announce"
      cancel-variant="outline-secondary"
      :ok-title="lang('t_send')"
      centered
      ok-only
      :title="lang('t_announcement')"
      @ok="sendAnnouncement"
    >
      <label for="message"> {{ lang("t_text") }}</label>
      <b-form-input
        v-model="announcement_message"
        id="message"
        :placeholder="lang('t_yourMessage')"
      />
    </b-modal>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      id="modal-status-change"
      cancel-variant="outline-secondary"
      :ok-title="lang('t_confirm')"
      centered
      ok-only
      :title="lang('t_changeAllStatuses')"
      @ok="change_status_selected_users"
    >
      <b-form-group>
        <label for="message">{{ lang("t_agent") }}</label>
        <v-select
          v-model="status_change_info.selected_users"
          class="mt-50"
          :reduce="(option) => option.Username"
          label="Username"
          :options="
            agents.filter(
              (e) =>
                !['RingingOut', 'InInteraction', 'AgentRinging '].includes(
                  e.Status
                )
            )
          "
          :placeholder="lang('t_agent')"
          multiple
          :closeOnSelect="false"
        >
          <template #list-header>
            <div class="w-100 mb-50">
              <b-button
                class="w-50"
                size="sm"
                variant="outline-primary"
                @click="
                  () => {
                    status_change_info.selected_users = agents
                      .filter(
                        (e) =>
                          ![
                            'RingingOut',
                            'InInteraction',
                            'AgentRinging ',
                          ].includes(e.Status)
                      )
                      .map((e) => e.Username);
                  }
                "
              >
                <feather-icon size="14" icon="CheckCircleIcon" />
                {{ lang("t_selectAll") }}
              </b-button>
              <b-button
                class="w-50"
                size="sm"
                variant="outline-danger"
                @click="
                  () => {
                    status_change_info.selected_users = [];
                  }
                "
              >
                <feather-icon size="14" icon="XOctagonIcon" />
                {{ lang("t_cleanSelections") }}
              </b-button>
            </div>
          </template>
        </v-select>
      </b-form-group>
      <b-form-group>
        <label for="message">{{ lang("t_status") }}</label>
        <v-select
          v-model="status_change_info.status"
          :options="spv_statuses"
          :reduce="(val) => val.display_name"
          :placeholder="lang('t_status')"
          label="display_name"
        >
        </v-select>
      </b-form-group>
    </b-modal>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      id="modal-queue-change"
      cancel-variant="outline-warning"
      :ok-title="lang('t_add')"
      :cancel-title="lang('t_remove')"
      centered
      :title="lang('t_changeAllQueues')"
      @ok="add_queues_selected_users"
      @cancel="remove_queues_selected_users"
    >
      <b-form-group>
        <label for="message">{{ lang("t_agent") }}</label>
        <v-select
          v-model="queue_change_info.selected_users"
          class="mt-50"
          :reduce="(option) => option.Username"
          label="Username"
          :options="
            agents.filter(
              (e) =>
                !['RingingOut', 'InInteraction', 'AgentRinging '].includes(
                  e.Status
                )
            )
          "
          :placeholder="lang('t_agent')"
          multiple
          :closeOnSelect="false"
        >
          <template #list-header>
            <div class="w-100 mb-50">
              <b-button
                class="w-50"
                size="sm"
                variant="outline-primary"
                @click="
                  () => {
                    queue_change_info.selected_users = agents
                      .filter(
                        (e) =>
                          ![
                            'RingingOut',
                            'InInteraction',
                            'AgentRinging ',
                          ].includes(e.Status)
                      )
                      .map((e) => e.Username);
                  }
                "
              >
                <feather-icon size="14" icon="CheckCircleIcon" />
                {{ lang("t_selectAll") }}
              </b-button>
              <b-button
                class="w-50"
                size="sm"
                variant="outline-danger"
                @click="
                  () => {
                    queue_change_info.selected_users = [];
                  }
                "
              >
                <feather-icon size="14" icon="XOctagonIcon" />
                {{ lang("t_cleanSelections") }}
              </b-button>
            </div>
          </template>
        </v-select>
      </b-form-group>
      <b-form-group>
        <label for="message">{{ lang("t_queue") }}</label>
        <v-select
          multiple
          v-model="queue_change_info.queues"
          :options="queues"
          :reduce="(val) => val.internal_name"
          :placeholder="lang('t_queue')"
          label="display_name"
        >
        </v-select>
      </b-form-group>
    </b-modal>
  </b-container>
</template>

<script>
import {
  BProgress,
  BProgressBar,
  BSpinner,
  BForm,
  BButtonGroup,
  BFormInput,
  BContainer,
  VBTooltip,
  BModal,
  BTable,
  BButton,
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BFormGroup,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BFormCheckbox,
  BBadge,
  BCardBody,
  BTabs, BTab
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { VueGoodTable } from "vue-good-table";
import Chat from "./Components/Chat.vue";

export default {
  components: {
    Chat,
    BProgress,
    BProgressBar,
    BSpinner,
    BForm,
    BButtonGroup,
    BFormInput,
    BContainer,
    VueGoodTable,
    BCardActions,
    BButton,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    vSelect,
    BFormGroup,
    VueApexCharts,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BFormCheckbox,
    BBadge,
    BCardBody,
    BCardCode,
    BTable,
    StatisticCardHorizontal,
    BModal,
    BTabs, BTab
  },
  directives: {
    "b-tooltip": VBTooltip,
  },

  data: () => ({
    lang: _l(window.localStorage.getItem("language") || "tr"),
    souffle_message: "",
    is_progress: false,
    announcement_message: "",
    filter_values: {
      selectedQueues: [],
      selectedQueues2: [],
    },
    status_change_info: {
      selected_users: [],
      status: "",
    },
    queue_change_info: {
      selected_users: [],
      queues: [],
    },
    searchQuery: "",
    selected_agent: {},
    queues: [],
    dont_show_go_home_status: true,
    queue_groups: [],
    selected_queue_group: [],
    revenue: {},
    data_timer: {},
    chart_timer: {},
    modal_agent_details: false,
    modal_interaction_details: false,
    fc_fields: [
      {
        key: "finish_code",
        label: globalThis._lang("t_finishCode"),
        sortable: true,
      },
      { key: "count", label: globalThis._lang("t_count"), sortable: true },
      { key: "rate", label: globalThis._lang("t_rate"), sortable: true },
      {
        key: "category",
        label: globalThis._lang("t_category"),
        sortable: true,
      },
    ],
    fc_category_colors: {
      Başarılı: "success",
      Başarısız: "warning",
      Randevu: "primary",
      Diğer: "secondary",
    },
    revenueComparisonLine: {
      series: [
        // {
        //   type: 'area',
        //   name: 'Gelen',
        //   data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
        // },
        {
          type: "area",
          name: globalThis._lang("t_abandonedCount"),
          data: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0,
          ],
        },
      ],
      chartOptions: {
        chart: {
          animations: {
            enabled: false,
            easing: "easeinout",
            speed: 800,
            animateGradually: {
              enabled: false,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: false,
              speed: 350,
            },
          },
          toolbar: { show: false }, // PDF PNG vb. uzantılarda indirmeni sağlıyor.
          zoom: { enabled: false },
          type: "line",
          offsetX: -10,
        },
        stroke: {
          curve: "smooth",
          // dashArray: [0, 12, 0],
          width: [4, 3, 4],
        },
        legend: {
          show: true,
          labels: {
            colors: [$themeColors.secondary],
            useSeriesColors: false,
          },
        },

        colors: [
          $themeColors.danger,
          $themeColors.primary,
          $themeColors.primary,
        ],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            inverseColors: false,
            gradientToColors: ["#ebe9f1"],
            shadeIntensity: 1,
            type: "vertical",
            opacityFrom: 0.75,
            opacityTo: 0.5,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
          hover: {
            size: 5,
          },
        },
        xaxis: {
          labels: {
            show: true, // Altta gözüken yazıları kapatıyor --   categories: ['01', '05', '09', '13', '17', '21', '26', '31'],
            style: {
              colors: "#b9b9c3",
              fontSize: "1rem",
            },
          },
          axisTicks: {
            show: false,
          },
          categories: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
          ],
          axisBorder: {
            show: false,
          },
          tickPlacement: "on",
        },
        yaxis: {
          ticks: {
            stepSize: 5,
            min: 0,
            fontColor: "#6e6b7b",
          },
          // tickAmount: 1,
          labels: {
            style: {
              colors: "#b9b9c3",
              fontSize: "1rem",
            },
            formatter(val) {
              return val > 999 ? `${(val / 1000).toFixed(0)}k` : val;
            },
          },
        },
        grid: {
          borderColor: "#e7eef7",
          padding: {
            top: -20,
            bottom: -10,
            left: 20,
          },
        },
        tooltip: {
          x: { show: false },
        },
      },
    },
    colors: {
      "Available": "success",
      "Lunch": "danger",
      "Break": "danger",
      "In Call - Outbound": "warning",
      "In Call - Inbound": "warning",
      "In Call - Dialer": "warning",
      "In Call - Transfer": "warning",
      "In Text": "warning",
      "Awaiting Transfer": "info",
      "ACW": "info",
      "Calling": "info",
      "Ringing": "info",
      "Offline": "secondary",
    },
    // { key: 'avatar', label: '' }
    fields: [
      { key: "Username", label: globalThis._lang("t_agent"), sortable: true },
      {
        key: "RemoteNumber",
        label: globalThis._lang("t_number"),
        sortable: true,
      },
      { key: "Queue", label: globalThis._lang("t_queue"), sortable: true },
      { key: "Reason", label: globalThis._lang("t_status"), sortable: true },
      { key: "time", label: globalThis._lang("t_duration"), sortable: true },
      {
        key: "Productivity",
        label: globalThis._lang("t_productivity"),
        sortable: true,
      },
      {
        key: "BreakTime",
        label: globalThis._lang("t_usedBreakTime"),
        sortable: true,
      },
    ],
    items: [
      {
        id: 1,
        avatar: require("@/assets/images/avatars/10-small.png"),
        Temsilci: "Ahmet İnanır",
        Dahili: "1011",
        Süre: "00:27:27",
        status: 1,
      },
    ],
    status: [
      {
        1: "Available",
        2: "In Call",
        3: "Break",
        4: "Offline",
        5: "Applied",
      },
      {
        1: "light-success",
        2: "light-warning",
        3: "light-primary",
        4: "light-danger",
      },
    ],
    agents: [],
    status_type: {},
    spv_statuses: [],
    status_categories: [],
    all_queues: [],
    queues: [],
    selected_queues: [],
    projects: [],
    visible_users: [],
    calls: [],
    hide_ofline_users: false,
    calls_info: {
      waiting: 0,
      abandoned: 0,
      total: 0,
    },
    top_bar_info: [
      {
        internal_name: "total_agent",
        subtitle: globalThis._lang("t_totalAgents"),
        content: 0,
        label: "",
        customClass: "",
        icon: "UserIcon",
        color: "light-info",
      },
      {
        internal_name: "available_agent",
        subtitle: globalThis._lang("t_available"),
        content: 0,
        label: "",
        customClass: "",
        icon: "UserCheckIcon",
        color: "light-success",
      },
      {
        internal_name: "incall_agent",
        subtitle: globalThis._lang("t_inCall"),
        content: 0,
        label: "",
        customClass: "",
        icon: "PhoneCallIcon",
        color: "light-warning",
      },
      {
        internal_name: "paused_agent",
        subtitle: globalThis._lang("t_paused"),
        content: 0,
        label: "",
        customClass: "",
        icon: "UserXIcon",
        color: "light-secondary",
      },
      {
        internal_name: "waiting_interactions",
        subtitle: globalThis._lang("t_callsInQueue"),
        content: 0,
        label: "",
        customClass: "cursor-pointer",
        icon: "WatchIcon",
        color: "light-primary",
      },
      {
        internal_name: "abandoned_interactions",
        subtitle: globalThis._lang("t_abandonedCount"),
        content: 0,
        label: "",
        customClass: "cursor-pointer",
        icon: "PhoneMissedIcon",
        color: "light-danger",
      },
    ],
    interaction_details_fields: [
      { label: globalThis._lang("t_date"), key: "date", sortable: true },
      { label: globalThis._lang("t_number"), key: "phone", sortable: true },
      { label: globalThis._lang("t_queue"), key: "queue", sortable: true },
      { label: globalThis._lang("t_waitTime"), key: "time", sortable: true },
      // { label: "Arandı Mı?", key: 'is_reached',sortable:true },
    ],
    interaction_details_data: [],
    chart_data_info: {
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
    },
    live_interval: {},
    timeInterval: [],
    wait_time_interval: {},
    selected_interaction_type: "",
    current_reason: "",
    full_screen: false,
    isBusy: false,
    conversations: [],
    closed_conversations: [],
    users: [],
  }),
  methods: {

    getStatusDetails(row) {
      if (["RingingOut", "InInteraction", "AgentRinging"].includes(row.Status) && row.CurrentInteraction) {
        if (row.CurrentInteraction.InteractionType != 'Text') {
          if (row.CurrentInteraction.Extra.Direction == "outbound") {
            return row.CurrentInteraction.Extra.Classification;
          } else if (row.CurrentInteraction.Extra.Direction == "inbound") {
            return row.CurrentInteraction.Extra.CallerIDName;
          } else {
            return row.CurrentInteraction.Extra.LocalNumber;
          }
        } else {
          return row.CurrentInteraction.Extra.CallerIDName;
        }
      } else {
        return "";
      }
    },
    toHHMMSS(sec_num) {
      try {
        return new Date(sec_num * 1000).toISOString().substr(11, 8);
      } catch (error) {
        return sec_num;
      }
    },
    set_abandon_call_fields() {
      this.interaction_details_fields = [
        { label: globalThis._lang("t_date"), key: "date", sortable: true },
        { label: globalThis._lang("t_number"), key: "phone", sortable: true },
        { label: globalThis._lang("t_queue"), key: "queue", sortable: true },
        { label: globalThis._lang("t_waitTime"), key: "time", sortable: true },
        // { label: "Arandı Mı?", key: 'is_reached',sortable:true },
      ];
    },
    onexport() {
      // On Click Excel download button
      let _headers = {};
      for (const header of this.interaction_details_fields) {
        _headers[header.key] = header.label;
      }

      let _data = [];
      for (const row of this.interaction_details_data) {
        let obj = {};
        delete row._rowVariant;

        for (const column of Object.keys(row)) {
          let key = _headers[column] == undefined ? column : _headers[column];
          obj[key] = row[column];
        }
        _data.push(obj);
      }

      var data = this.$xlsx.utils.json_to_sheet(_data);

      // A workbook is the name given to an Excel file
      var wb = this.$xlsx.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      this.$xlsx.utils.book_append_sheet(wb, data, `AbandonedList`); // sheetAName is name of Worksheet

      // export Excel file
      this.$xlsx.writeFile(wb, `AbandonedList_${new Date().getTime()}.xlsx`); // name of the file is 'book.xlsx'
    },

    xlsxToJson(data) {
      var jsonData = [];
      let headers = data[0];
      let _data = data.slice(1);
      let objectHolder = [];
      for (let row of _data) {
        let _row = [];
        for (var i = 0; i < row.length; i++) {
          _row.push([headers[i], row[i]]);
        }
        jsonData.push(Object.fromEntries(_row));
      }
      return jsonData;
    },

    async sendAnnouncement() {
      if (this.announcement_message == "") {
        return;
      } else {
        globalThis._voyce.$qm.emit("Chat:AnnouncementMessage", {
          message: this.announcement_message,
          project: globalThis.user.selected_project,
        });
        this.announcement_message = "";
      }
    },
    async sendSouffle() {
      if (this.souffle_message == "") {
        return;
      } else {
        console.log("this.selected_agent.Username", this.selected_agent);
        globalThis._voyce.$qm.emit("Chat:SouffleMessage", {
          target: `${this.selected_agent.Username}@${this.selected_agent.Domain}`,
          message: this.souffle_message,
        });

        this.souffle_message = "";
      }
    },
    call(unique_id, type) {
      globalThis.vSip.RegisterHandlers({
        progress: function (e) {
          // console.log('call is in progressaaaa OUTBOUND');
        },
        failed: function (e) {
          // console.log('call failed with cause3:  OUTBOUND', e);
        },
        ended: function (e) {
          // console.log('ended SPV', e);
          globalThis.LayoutContentRendererDefault.interaction[
            globalThis.LayoutContentRendererDefault.selected_line
          ] = null;
        },
        confirmed: function (e) {
          // console.log('call confirmed OUTBOUND');
        },
        peerconnection: function (e) {
          // console.log('call peerconnection OUTBOUND');
        },
        connecting: function (e) { },
        accepted: function (e) {
          // console.log('call accepted OUTBOUND');
        },
      });

      if (
        globalThis.LayoutContentRendererDefault.interaction[
        globalThis.LayoutContentRendererDefault.selected_line
        ] == null
      ) {
        console.log("GİRDİ", unique_id, type);

        globalThis.LayoutContentRendererDefault.interaction[
          globalThis.LayoutContentRendererDefault.selected_line
        ] = globalThis.vSip.Call(`chanspy_${unique_id}`, [
          `X-Domain: ${globalThis.user.domain}`,
          `X-Type: ${type}`,
        ]);
      }
    },
    async chanspy(type) {
      if (globalThis.LayoutContentRendererDefault.interaction[1] != null) {
        globalThis.LayoutContentRendererDefault.interaction[1].terminate();
        globalThis.LayoutContentRendererDefault.interaction[1] = null;
        return;
      }

      await this.get_live_agent();
      let _user = this.agents.find(
        (e) => e.Username == this.selected_agent.Username
      );

      let unique_id = _user.CurrentInteraction.InteractionID;

      if (type == "join") {
        this.call(unique_id, type);
      }
      if (type == "souffle") {
        this.call(unique_id, type);
      }
      if (type == "listen") {
        this.call(unique_id, type);
      }
    },
    change_status_selected_users() {
      for (const agent of this.status_change_info.selected_users) {
        if (this.status_change_info.status != "Available") {
          globalThis._voyce.$qm.emit("qmgr:SPVPause", {
            reason: this.status_change_info.status,
            target_agent: agent,
          });
        } else {
          globalThis._voyce.$qm.emit("qmgr:SPVUnpause", {
            target_agent: agent,
          });
        }
      }
      this.status_change_info = {
        selected_users: [],
        status: "",
      };
    },
    add_queues_selected_users() {
      for (const agent of this.queue_change_info.selected_users) {
        globalThis._voyce.$qm.emit("qmgr:SPVJoinQueue", {
          target_agent: agent,
          queues: this.queue_change_info.queues,
        });

      }
      this.queue_change_info = {
        selected_users: [],
        queues: "",
      };
      this.get_live_agent();
    },
    remove_queues_selected_users() {
      for (const agent of this.queue_change_info.selected_users) {
        globalThis._voyce.$qm.emit("qmgr:SPVLeaveQueue", {
          target_agent: agent,
          queues: this.queue_change_info.queues,
        });

      }
      this.queue_change_info = {
        selected_users: [],
        queues: "",
      };
      this.get_live_agent();
    },
    add_queue(queue) {
      // console.log("add_queue", queue);
      globalThis._voyce.$qm.emit("qmgr:SPVJoinQueue", {
        target_agent: this.selected_agent.Username,
        queues: [queue[queue.length - 1].internal_name],
      });
      this.get_live_agent();

    },
    remove_queue(queue) {
      // console.log("remove_queue", queue.internal_name);
      globalThis._voyce.$qm.emit("qmgr:SPVLeaveQueue", {
        target_agent: this.selected_agent.Username,
        queues: [queue.internal_name],
      });
      this.get_live_agent();

    },
    change_user_state() {
      if (this.current_reason != "Available") {
        globalThis._voyce.$qm.emit("qmgr:SPVPause", {
          reason: this.current_reason,
          target_agent: this.selected_agent.Username,
        });
      } else {
        globalThis._voyce.$qm.emit("qmgr:SPVUnpause", {
          target_agent: this.selected_agent.Username,
        });
      }
      this.get_live_agent();

    },
    async showDetails(item) {
      // console.log("item", item);
      this.selected_agent = item;

      var response = (
        await this.$http_in.get(`qmgr/v1/LiveAgent/${item.Username}`)
      ).data;
      this.selected_agent.details = {
        productivity: response.Productivity.calculated || 0,
        inbound_success: response.AcceptedCalls || 0,
        inbound_failed: response.MissedCalls || 0,
        outbound_success: ((response.SuccessfulOutboundCalls ?? 0) + (response?.AcceptedDialerCalls ?? 0)),
        outbound_failed: response.FailedOutboundcalls || 0,
      };
      this.selected_agent.details.total_break_time = globalThis.projects.find(
        (e) => e.internal_name == globalThis.user.selected_project
      ).break_time;
      this.selected_agent.details.break_time = 0;
      for (const item of Object.keys(response.ReasonLengths)) {
        if (item == "") {
          continue;
        }
        let _statu = globalThis.statuses.find((e) => e.display_name == item);
        if (_statu && _statu.show_in_dashboard) {
          if (
            globalThis.status_categories.find(
              (e) => e._id == _statu.status_category_id
            ).internal_name == "break"
          ) {
            this.selected_agent.details.break_time +=
              response.ReasonLengths[item];
          }
        }
      }
      this.selected_agent.details.fc_data = [];
      this.selected_agent.details.total_finish_code_counts = 0;
      for (const item of Object.keys(response.FinishCodeCounts)) {
        this.selected_agent.details.total_finish_code_counts +=
          response.FinishCodeCounts[item];
      }

      for (const item of Object.keys(response.FinishCodeCounts)) {
        let _fc = globalThis.finish_codes.find((j) => j.finish_code == item);
        if (_fc && !_fc.show_in_dashboard) {
          let _other = this.selected_agent.details.fc_data.find(
            (e) => e.finish_code == "Diğer"
          );
          if (_other != undefined) {
            _other.count += response.FinishCodeCounts[item];
            _other.rate += response.FinishCodeCounts[item];
          } else {
            this.selected_agent.details.fc_data.push({
              finish_code: "Diğer",
              count: response.FinishCodeCounts[item],
              rate: response.FinishCodeCounts[item],
              category: "Tanımsız",
            });
          }
        } else {
          if (_fc) {
            this.selected_agent.details.fc_data.push({
              finish_code: item,
              count: response.FinishCodeCounts[item],
              rate: response.FinishCodeCounts[item],
              category: globalThis.finish_categories.find(
                (e) => e._id == _fc.category_id
              ).category,
            });
          }
        }
      }

      if (
        this.selected_agent.details.total_break_time <=
        this.selected_agent.details.break_time
      ) {
        this.selected_agent.details.remaining_break_time = this.toHHMMSS(0);
        this.selected_agent.details.break_time_fraud = true;
      } else {
        this.selected_agent.details.remaining_break_time = this.toHHMMSS(
          this.selected_agent.details.total_break_time -
          this.selected_agent.details.break_time
        );
        this.selected_agent.details.break_time = this.toHHMMSS(
          this.selected_agent.details.break_time
        );
        this.selected_agent.details.break_time_fraud = false;
      }
      this.selected_agent.details.break_time = this.toHHMMSS(
        this.selected_agent.details.break_time
      );

      this.current_reason = item.Reason;
      this.modal_agent_details = true;
      this.filter_values.selectedQueues = item.Queues.Value.filter(
        (e) => e && !e.includes("personal")
      );

      try {
        let _user = this.users.find(e => e.username == this.selected_agent.Username);
        globalThis.chat_user = _user;
        globalThis.spv_external_chat.set_conf();
      } catch (error) {

      }


    },
    queue_group_changed() {
      // console.log("queue_group_changed", this.selected_queue_group)
      let queues = [];
      for (const item of this.selected_queue_group) {
        queues = [...queues, ...item.queues.map((e) => e.internal_name)];
      }
      // console.log("queues", queues)
      this.selected_queues = [];
      this.selected_queues = Array.from(
        new Set([...this.selected_queues, ...queues])
      );
      this.get_live_agent();
      this.get_live_queue();
    },
    async abandon_call_details() {
      this.isBusy = true;
      this.interaction_details_fields = [
        { label: globalThis._lang("t_date"), key: "date", sortable: true },
        { label: globalThis._lang("t_number"), key: "phone", sortable: true },
        { label: globalThis._lang("t_queue"), key: "queue", sortable: true },
        { label: globalThis._lang("t_waitTime"), key: "time", sortable: true },
        { label: "Arandı Mı?", key: "is_reached", sortable: true },
      ];
      var abandoned_list = (
        await this.$http_in.post(`spv/v1/AbandonCallDetails`, {
          phone_list: this.interaction_details_data.map((e) => e.phone),
        })
      ).data;
      for (const item of this.interaction_details_data) {
        console.log("item", item);
        let tmp = abandoned_list.filter(
          (e) =>
            e.StartTime > item.timestamp && e.BaseRemoteNumber == item.phone
        );
        console.log("tmp", tmp);

        item._rowVariant = tmp.length > 0 ? "success" : "danger";
        item.is_reached = tmp.length > 0 ? true : false;
      }
      this.interaction_details_data = [...this.interaction_details_data];
      this.isBusy = false;
    },
    get_interaction_details: async function (type) {
      try {
        this.is_progress = true;
        this.isBusy = true;

        this.interaction_details_data = [];
        // console.log("this.wait_time_interval", this.wait_time_interval);
        clearInterval(this.wait_time_interval);
        // console.log("this.wait_time_interval2", this.wait_time_interval);

        var response = (await this.$http_in.get(`qmgr/v1/LiveQueue`)).data;

        // console.log("type", type)
        // console.log("get_interaction_details", response)
        for (const queue of response) {
          // console.log("queue.Name", queue.Name);
          if (this.selected_queues.includes(queue.Name)) {
            if (type == "waiting_interactions") {
              this.modal_interaction_details = true;

              this.selected_interaction_type = type;
              for (const item of queue.Statistics.AwaitingInteractions) {
                this.interaction_details_data.push({
                  queue: item.Queue,
                  timestamp: new Date(item.LastEventDate + 10800000).getTime(),
                  date: new Date(item.LastEventDate + 10800000)
                    .toISOString()
                    .replace("T", " ")
                    .replace("Z", ""),
                  phone: item.Extra.RemoteNumber.substr(-11),
                  time: parseInt(
                    (new Date().getTime() - item.LastEventDate) / 1000
                  ),
                });
              }
            } else if (type == "abandoned_interactions") {
              this.modal_interaction_details = true;

              this.selected_interaction_type = type;
              // console.log("this.selected_interaction_type", this.selected_interaction_type);

              for (const item of queue.AbandonedInteractionsList.filter(
                (e) => e.QueueWaitTime != null
              )) {
                this.interaction_details_data.push({
                  queue: item.Details.Queue,
                  timestamp: new Date(item.AbandonTime).getTime(),
                  date: new Date(item.AbandonTime + 10800000)
                    .toISOString()
                    .replace("T", " ")
                    .replace("Z", ""),
                  phone: item.Details.RemoteNumber.substr(-10),
                  time: item.QueueWaitTime || 0,
                  // _rowVariant: tmp.length > 0 ? 'success' : 'danger',
                  // is_reached: tmp.length > 0 ? true : false
                });
              }
            }
          }
        }

        if (type == "waiting_interactions") {
          this.modal_interaction_details = true;

          this.wait_time_interval = setInterval(() => {
            for (const row of this.interaction_details_data) {
              row.time++;
            }
          }, 1000);
        }
        this.isBusy = false;
      } catch (error) {
      } finally {
        this.is_progress = false;
      }
    },
    get_live_queue: async function () {
      var response = (await this.$http_in.get(`qmgr/v1/LiveQueue`)).data;

      this.bar_info_clear();

      let _AwaitingInteractionsCount = 0;
      let _AbandonedCount = 0;
      var tmp_abandon = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ];
      for (const item of response) {
        if (this.selected_queues.includes(item.Name)) {
          for (const row of item.AbandonedInteractionsList.filter(
            (e) => e.QueueWaitTime != null
          )) {
            tmp_abandon[new Date(row.AbandonTime).getHours()]++;
          }

          _AwaitingInteractionsCount += Number(
            item.Statistics.AwaitingInteractionsCount
          );
          // this.top_bar_info[4].content += Number(item.Statistics.AwaitingInteractionsCount);
          _AbandonedCount += Number(item.Statistics.AbandonedCount);
          // this.top_bar_info[5].content += Number(item.Statistics.AbandonedCount);
        }
      }
      this.top_bar_info[4].content = _AwaitingInteractionsCount;
      this.top_bar_info[5].content = _AbandonedCount;
      // this.revenueComparisonLine.series[0].data = [...tmp_abandon];

      this.top_bar_info[0].content = this.agents.filter(
        (e) => e.visible_in_reports
      ).length;
      this.top_bar_info[1].content = this.agents.filter(
        (e) => e.Status == "Available" && e.visible_in_reports
      ).length;
      this.top_bar_info[2].content = this.agents.filter(
        (e) => e.Status == "InInteraction" && e.visible_in_reports
      ).length;
      let break_count = 0;
      let paused_agent = this.agents.filter(
        (e) => e.Reason != "" && e.Status == "Paused" && e.visible_in_reports
      );
      for (const row of paused_agent) {
        if (
          this.status_categories.find(
            (j) => j._id == this.status_type[row.Reason]
          )?.internal_name == "break"
        ) {
          break_count++;
        }
      }
      this.top_bar_info[3].content = break_count;
    },
    get_live_agent: async function () {
      for (const interval of this.timeInterval) {
        clearInterval(interval);
      }
      this.bar_info_clear();

      var response = (await this.$http_in.get(`qmgr/v1/LiveAgent`)).data;
      // console.log("get_live_agent", response);
      this.agents = [];

      // console.log("aaa", this.visible_users);
      for (const row of response.filter(e => e != null)) {
        let _total_break_time = globalThis.projects.find(
          (e) => e.internal_name == globalThis.user.selected_project
        ).break_time;
        let _break_time = 0;
        let _break_time_fraud = false;
        for (const item of Object.keys(row.ReasonLengths)) {
          let _statu = globalThis.statuses.find((e) => e.display_name == item);
          if (_statu && item != "" && _statu.show_in_dashboard) {
            if (
              globalThis.status_categories.find(
                (e) => e._id == _statu.status_category_id
              ).internal_name == "break"
            ) {
              _break_time += row.ReasonLengths[item];
            }
          }
        }
        if (_total_break_time <= _break_time) {
          _break_time_fraud = true;
        } else {
          _break_time = this.toHHMMSS(_break_time);
          _break_time_fraud = false;
        }
        _break_time = this.toHHMMSS(_break_time);

        for (const queue of row.Queues.Value) {
          if (
            this.selected_queues.includes(queue) &&
            this.selected_queues.length > 0
          ) {
            this.agents.push({
              ...row,
              visible_in_reports: this.visible_users[row.Identifier],
              time: Number((new Date() - new Date(row.LastActionDate)) / 1000),
              Productivity: `%${row.Productivity.calculated}`,
              RemoteNumber: [
                "RingingOut",
                "InInteraction",
                "AgentRinging ",
              ].includes(row.Status)
                ? row.CurrentInteraction
                  ? row.CurrentInteraction.Extra.RemoteNumber
                  : ""
                : "",
              Queue: ["RingingOut", "InInteraction", "AgentRinging "].includes(
                row.Status
              )
                ? row.CurrentInteraction
                  ? row.CurrentInteraction.Extra.Direction == "outbound"
                    ? row.CurrentInteraction.Extra.Queue
                    : row.CurrentInteraction.Queue
                  : ""
                : "",
              Classification: this.getStatusDetails(row),
              BreakTime: _break_time,
              BreakTimeFraud: _break_time_fraud,
            });
            break;
          }
        }
      }

      if (!this.dont_show_go_home_status) {
        for (const queue of this.all_queues) {
          if (
            this.selected_queues.includes(queue.internal_name) &&
            this.selected_queues.length > 0
          ) {
            for (const agent of queue.static_users) {
              let _agent = this.agents.find(
                (e) => e.Username + "@" + e.Domain == agent
              );
              if (!_agent) {
                this.agents.push({
                  Username: agent.split("@")[0],
                  Reason: "Not Connected",
                  Status: "",
                  visible_in_reports: this.visible_users[agent],
                  time: 0,
                  Productivity: ``,
                  RemoteNumber: "",
                  Queue: "",
                  Classification: "",
                  Domain: globalThis.user.domain,
                  BreakTime: "00:00:00",
                  BreakTimeFraud: false,
                });
              }
            }
          }
        }
      }

      // console.log("this.agents", this.agents);
      // console.log("this.status_categories", this.status_categories);

      this.top_bar_info[0].content = this.agents.filter(
        (e) => e.visible_in_reports
      ).length;
      this.top_bar_info[1].content = this.agents.filter(
        (e) => e.Status == "Available" && e.visible_in_reports
      ).length;
      this.top_bar_info[2].content = this.agents.filter(
        (e) => e.Status == "InInteraction" && e.visible_in_reports
      ).length;
      this.top_bar_info[3].content = this.agents.filter(
        (e) =>
          e.Status == "Paused" &&
          this.status_categories.find(
            (j) => j._id == this.status_type[e.Reason || "Available"]
          ).internal_name == "break" &&
          e.visible_in_reports
      ).length;

      this.timeInterval.push(
        setInterval(() => {
          for (const row of this.agents) {
            row.time++;
          }
        }, 1000)
      );
    },
    GetStatus: async function () {
      var response = (await this.$http_in.get(`optmgr/v1/Status`)).data;

      this.status_type = {};
      for (const item of response.status) {
        this.status_type[item.display_name] = item.status_category_id;
      }

      this.status_categories = response.categories;
      this.spv_statuses = response.status.filter((e) => e.spv_changeable);
    },
    timer(seconds) {
      let _m = ("" + (Math.floor(seconds / 60) % 60)).padStart(2, "0");
      let _h = ("" + Math.floor(seconds / 3600)).padStart(2, "0");
      let _s = ("" + Math.floor(seconds % 60)).padStart(2, "0");
      return `${_h}:${_m}:${_s}`;
    },
    GetQueues: async function () {
      var response = (await this.$http_in.get(`spv/v1/Queue`)).data;
      // console.log('queues', response);
      let p_queues = globalThis.permissions["queue"];
      let default_push = this.selected_queues.length > 0 ? false : true;
      this.all_queues = response;
      for (const item of response) {
        if (p_queues.includes(item.internal_name)) {
          this.queues.push(item);
          if (default_push) {
            this.selected_queues.push(item.internal_name);
          }
        }
      }
    },
    GetQueueGroup: async function () {
      var response = (await this.$http_in.get(`/optmgr/v1/QueueGroup`)).data;
      // console.log('queues', response);
      // let p_queues = globalThis.permissions["queue"]

      this.queue_groups = response;
      // for (const item of response) {
      //   if (p_queues.includes(item.internal_name)) {
      //     this.queues.push(item);
      //     this.selected_queues.push(item.internal_name);
      //   }
      // }
    },
    GetProjects: async function () {
      var response = (await this.$http_in.get(`spv/v1/Project`)).data;

      this.projects = response;
    },
    get_users: async function () {
      var response = (await this.$http_in.get(`spv/v1/User`)).data;
      this.users = response;
      for (const row of response) {
        this.visible_users[row.identifier] = row.is_visible_in_live_watch;
      }
    },
    bar_info_clear() {
      // this.calls_info.waiting = 0;
      // this.calls_info.abandoned = 0;
      this.top_bar_info = [
        {
          internal_name: "total_agent",
          subtitle: globalThis._lang("t_totalAgents"),
          content: 0,
          label: "",
          customClass: "",
          icon: "UserIcon",
          color: "light-info",
        },
        {
          internal_name: "available_agent",
          subtitle: globalThis._lang("t_available"),
          content: 0,
          label: "",
          customClass: "",
          icon: "UserCheckIcon",
          color: "light-success",
        },
        {
          internal_name: "incall_agent",
          subtitle: globalThis._lang("t_inCall"),
          content: 0,
          label: "",
          customClass: "",
          icon: "PhoneCallIcon",
          color: "light-warning",
        },
        {
          internal_name: "paused_agent",
          subtitle: globalThis._lang("t_paused"),
          content: 0,
          label: "",
          customClass: "",
          icon: "UserXIcon",
          color: "light-secondary",
        },
        {
          internal_name: "waiting_interactions",
          subtitle: globalThis._lang("t_callsInQueue"),
          content: this.top_bar_info[4].content,
          label: "",
          customClass: "cursor-pointer",
          icon: "WatchIcon",
          color: "light-primary",
        },
        {
          internal_name: "abandoned_interactions",
          subtitle: globalThis._lang("t_abandonedCount"),
          content: this.top_bar_info[5].content,
          label: "",
          customClass: "cursor-pointer",
          icon: "PhoneMissedIcon",
          color: "light-danger",
        },
      ];
    },
    reset_chart_data_info() {
      this.chart_data_info = {
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        13: 0,
        14: 0,
        15: 0,
        16: 0,
        17: 0,
        18: 0,
        19: 0,
        20: 0,
        21: 0,
        22: 0,
        23: 0,
      };
    },
    AgentStatusChange(data) {
      // console.log("AgentStatusChange", data);
      let _user = this.agents.find((e) => e.Username == data.Username);

      if (_user != undefined) {
        _user.Reason = data.Reason;
        _user.Status = data.Status;
        _user.time = 0;
      }

      this.top_bar_info[0].content = this.agents.filter(
        (e) => e.visible_in_reports
      ).length;
      this.top_bar_info[1].content = this.agents.filter(
        (e) => e.Status == "Available" && e.visible_in_reports
      ).length;
      this.top_bar_info[2].content = this.agents.filter(
        (e) => e.Status == "InInteraction" && e.visible_in_reports
      ).length;
      let break_count = 0;
      let paused_agent = this.agents.filter(
        (e) => e.Reason != "" && e.Status == "Paused" && e.visible_in_reports
      );
      for (const row of paused_agent) {
        // console.log("row", row);

        if (
          this.status_categories.find(
            (j) => j._id == this.status_type[row.Reason]
          )?.internal_name == "break"
        ) {
          break_count++;
        }
      }
      this.top_bar_info[3].content = break_count;
    },
    AgentJoinedQueue(data) {
      // console.log("AgentJoinedQueueSPV", data);

      // if (this.agents.find((e) => e.Username == data.Username) == undefined) {
      //   this.agents.push({
      //     ...data,
      //     Reason: data.Reason,
      //     visible_in_reports: this.visible_users[data.Username + "@" + data.Domain],
      //     time: 0,
      //     Queue: "",
      //     RemoteNumber: "",
      //   });
      // }

      // this.top_bar_info[0].content = this.agents.filter(
      //   (e) => e.visible_in_reports
      // ).length;
      // this.top_bar_info[1].content = this.agents.filter(
      //   (e) => e.Status == "Available" && e.visible_in_reports
      // ).length;
      // this.top_bar_info[2].content = this.agents.filter(
      //   (e) => e.Status == "InInteraction" && e.visible_in_reports
      // ).length;
      // let break_count = 0;
      // let paused_agent = this.agents.filter(
      //   (e) => e.Reason != "" && e.Status == "Paused" && e.visible_in_reports
      // );
      // for (const row of paused_agent) {
      //   if (
      //     this.status_categories.find((j) => j._id == this.status_type[row.Reason])
      //       ?.internal_name == "break"
      //   ) {
      //     break_count++;
      //   }
      // }
      // this.top_bar_info[3].content = break_count;
      this.get_live_agent();
    },
    AgentLeftQueue(data) {
      // console.log("AgentLeftQueueSPV", data);
      this.get_live_agent();
    },
    InteractionJoinedQueue(data) {
      if (this.selected_queues.includes(data.Queue)) {
        this.top_bar_info[4].content++;
      }
    },

    InteractionSentToAgent(data) {
      console.log(data);
      let _user = this.agents.find((e) => e.Username == data.Agent);

      if (_user != undefined) {
        _user.RemoteNumber = data.InteractionType == 'Text' ? data.Extra.RemoteNumber : data.Extra.RemoteNumber.substr(-10);
        _user.Queue =
          data.Extra.Direction == "outbound" ? data.Extra.Queue : data.Queue;
        if (data.InteractionType != 'Text') {
          if (data.Extra.Direction == "outbound") {
            _user.Classification = data.Extra.Classification;
          }
          if (data.Extra.Direction == "inbound") {
            _user.Classification = data.Extra.CallerIDName;
          }
          if (data.Extra.Direction == "dialer") {
            _user.Classification = data.Extra.LocalNumber;
          }
        }

        // _user.Classification = data.Extra.Direction == 'outbound' ? data.Extra.Classification : data.Extra.CallerIDName;
      }
    },
    TransferSentToAgent(data) {
      console.log(data);
      let _user = this.agents.find((e) => e.Username == data.To);

      if (_user != undefined) {
        _user.RemoteNumber = data.Extra.RemoteNumber.substr(-10);
        _user.Queue =
          data.Extra.Direction == "outbound" ? data.Extra.Queue : data.Queue;
        if (data.Extra.Direction == "outbound") {
          _user.Classification = data.Extra.Classification;
        }
        if (data.Extra.Direction == "inbound") {
          _user.Classification = data.Extra.CallerIDName;
        }
        if (data.Extra.Direction == "dialer") {
          _user.Classification = data.Extra.LocalNumber;
        }
        // _user.Classification = data.Extra.Direction == 'outbound' ? data.Extra.Classification : data.Extra.CallerIDName;
      }
    },
    AgentAcceptedInteraction(data) {
      // console.log("this.agents", this.agents);

      if (this.selected_queues.includes(data.Queue)) {
        if (Number(this.top_bar_info[4].content) > 0) {
          this.top_bar_info[4].content--;
        }
      }
    },
    InteractionAbandoned(data) {
      // console.log("InteractionAbandoned SPV", data);
      // console.log("this.revenueComparisonLine.serie0s", this.revenueComparisonLine.series);

      if (this.selected_queues.includes(data.Queue)) {
        this.top_bar_info[5].content++;
        if (Number(this.top_bar_info[4].content) > 0) {
          this.top_bar_info[4].content--;
        }
        // ++this.revenueComparisonLine.series[0].data[
        //   new Date(data.EventTime).getHours()
        // ];
        // console.log("this.revenueComparisonLine.series", this.revenueComparisonLine.series);
        // this.revenueComparisonLine.series = [
        //   ...this.revenueComparisonLine.series,
        // ];
      }
    },
    InteractionRemovedFromQueue(data) {
      if (this.selected_queues.includes(data.Queue)) {
        if (Number(this.top_bar_info[4].content) > 0) {
          this.top_bar_info[4].content--;
        }
      }
    },
    InteractionSuccessful(data) {
      console.log("InteractionSuccessful", data);
      let _user = this.agents.find((e) => e.Username == data.Agent);

      if (_user != undefined) {
        _user.RemoteNumber = "";
        _user.Queue = "";
        _user.Classification = "";
      }
    },
    AgentTransferRinging(data) {
      console.log("AgentTransferRinging", data);
      // let _user = this.agents.find((e) => e.Username == data.Username);
      // console.log("AgentTransferredCall_user", _user);

      // if (_user != undefined) {
      //   _user.RemoteNumber = "";
      //   _user.Queue = "";
      //   _user.Classification = "";
      // }
    },
    AgentTransferredCall(data) {
      console.log("AgentTransferredCall", data);
      let _user = this.agents.find((e) => e.Username == data.Username);
      console.log("AgentTransferredCall_user", _user);

      if (_user != undefined) {
        _user.RemoteNumber = "";
        _user.Queue = "";
        _user.Classification = "";
      }
    },
    AgentLostCall(data) {
      console.log("AgentLostCall", data);
      let _user = this.agents.find((e) => e.Username == data.Username);

      if (_user != undefined) {
        _user.RemoteNumber = "";
        _user.Queue = "";
        _user.Classification = "";
      }
    },
  },
  mounted: async function () {
    globalThis.spv_dashboard = this;

    // const this_data = JSON.parse(localStorage.getItem("spv_dashboard_cache"));

    // if (![undefined, null].includes(this_data)) {
    //   this.selected_queues = this_data.selected_queues;
    //   this.selected_queue_group = this_data.selected_queue_group;
    // }

    let spvQueues = JSON.parse(localStorage.getItem("spvQueues"));
    if (![undefined, null].includes(spvQueues)) {
      let _data = spvQueues.find((e) => e.username == globalThis.username);
      if (![undefined, null].includes(_data)) {
        this.selected_queues = _data.selected_queues;
        this.selected_queue_group = _data.selected_queue_group;
      }
    }

    await this.GetQueues();
    await this.GetQueueGroup();
    await this.get_users();
    await this.GetStatus();
    await this.GetProjects();

    await this.get_live_queue();
    await this.get_live_agent();

    this.live_interval = setInterval(() => {
      this.get_live_agent();
      this.get_live_queue();
    }, 5 * 60 * 1000);
  },
  beforeDestroy: function () {
    let _spvQueues = JSON.parse(localStorage.getItem("spvQueues"));
    if (![undefined, null].includes(_spvQueues)) {
      let _data = _spvQueues.find((e) => e.username == globalThis.username);
      if (![undefined, null].includes(_data)) {
        _data.selected_queues = this.selected_queues;
        _data.selected_queue_group = this.selected_queue_group;
      } else {
        _spvQueues.push({
          username: globalThis.username,
          selected_queues: this.selected_queues,
          selected_queue_group: this.selected_queue_group,
        });
      }
    } else {
      _spvQueues = [];
      _spvQueues.push({
        username: globalThis.username,
        selected_queues: this.selected_queues,
        selected_queue_group: this.selected_queue_group,
      });
    }

    localStorage.setItem("spvQueues", JSON.stringify(_spvQueues));

    clearInterval(this.chart_timer);
    clearInterval(this.data_timer);
    clearInterval(this.live_interval);
    for (const interval of this.timeInterval) {
      clearInterval(interval);
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
