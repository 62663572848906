<template>
  <div>
    <!-- Table Container Card -->

    <b-row class="mx-0">
      <b-col cols="4" class="px-0">
        <b-card
          no-body
          class="mb-0 border-right"
          style="height: 50vh; overflow-y: auto"
        >
          <div>
            <b-row class="border-bottom mx-0">
              <b-col>
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block round my-1"
                  :placeholder="lang('t_search')"
                />
              </b-col>
            </b-row>
            <b-row align-h="center" class="mx-0 mt-1">
              <b-avatar
                class="mx-50 cursor-pointer"
                :badge="
                  conversations
                    .filter((e) => e.source == 'chat')
                    .length.toString()
                "
                badge-variant="success"
                :variant="'light-success'"
              >
                <feather-icon icon="MessageCircleIcon"></feather-icon>
              </b-avatar>
              <b-avatar
                class="mx-50 cursor-pointer"
                :badge="
                  conversations
                    .filter((e) => e.source == 'instagram')
                    .length.toString()
                "
                badge-variant="danger"
                :variant="'light-danger'"
              >
                <feather-icon icon="InstagramIcon"></feather-icon>
              </b-avatar>
              <b-avatar
                class="mx-50 cursor-pointer"
                :badge="
                  conversations
                    .filter((e) => e.source == 'mail')
                    .length.toString()
                "
                badge-variant="warning"
                :variant="'light-warning'"
              >
                <feather-icon icon="MailIcon"></feather-icon>
              </b-avatar>
              <b-avatar
                class="mx-50 cursor-pointer"
                :badge="
                  conversations
                    .filter((e) => e.source == 'telegram')
                    .length.toString()
                "
                badge-variant="primary"
                :variant="'light-primary'"
              >
                <feather-icon icon="SendIcon"></feather-icon>
              </b-avatar>
              <b-avatar
                class="mx-50 cursor-pointer"
                :badge="
                  conversations
                    .filter((e) => e.source == 'twitter')
                    .length.toString()
                "
                badge-variant="info"
                :variant="'light-info'"
              >
                <feather-icon icon="TwitterIcon"></feather-icon>
              </b-avatar>
            </b-row>
            <b-row class="mx-0">
              <h4 class="text-primary m-1">Konuşmalar</h4>
            </b-row>
            <b-row class="mx-0">
              <b-list-group class="w-100 mx-1">
                <b-list-group-item
                  v-for="(item, i) in conversations.filter(
                    (e) => e.status == 'open'
                  )"
                  class="border-0 rounded my-25 d-flex justify-content-between align-items-center"
                  href="#some-link"
                  :key="i"
                  @click="get_conversation_details(item.id)"
                  :variant="
                    conversation &&
                    conversation.id &&
                    conversation.id === item.id
                      ? 'primary'
                      : ''
                  "
                >
                  <b-media no-body>
                    <b-media-aside class="mr-1">
                      <b-avatar
                        :class="
                          conversation &&
                          conversation.id &&
                          conversation.id === item.id
                            ? 'badge-glow'
                            : ''
                        "
                        :badge="
                          conversation &&
                          conversation.id &&
                          conversation.id === item.id
                            ? ''
                            : item.messages
                                .filter((e) => e.seen_at == null)
                                .length.toString()
                        "
                        :badge-variant="
                          conversation &&
                          conversation.id &&
                          conversation.id === item.id
                            ? ''
                            : 'success'
                        "
                        :variant="
                          conversation &&
                          conversation.id &&
                          conversation.id === item.id
                            ? 'success'
                            : 'light-success'
                        "
                      >
                        {{ item.customer.title.slice(0, 1).toUpperCase() }}
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h5 class="font-weight-bolder mb-0">
                        {{ item.customer.title }}
                      </h5>
                      <b-card-text class="font-small-4 mb-0">
                        <small
                          style="
                            display: inline-block;
                            width: 10vw;
                            white-space: nowrap;
                            overflow: hidden !important;
                            text-overflow: ellipsis;
                          "
                          >{{
                            item.messages[item.messages.length - 1].body
                          }}</small
                        >
                      </b-card-text>
                      <b-card-text class="font-small-4 mb-0">
                        <b-row class="mx-0">
                          <b-badge class="mr-25" variant="success" size="sm">{{
                            item.source
                          }}</b-badge>
                          <!-- <b-badge
                            v-for="(tag, j) in item.tags"
                            :key="j"
                            class="mr-25"
                            size="sm"
                            >{{ tag }}</b-badge
                          > -->
                        </b-row>
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                  <b-badge
                    :variant="
                      conversation &&
                      conversation.id &&
                      conversation.id === item.id
                        ? 'secondary'
                        : 'light-secondary'
                    "
                    >{{
                      new Date(item.last_activity_at).toLocaleTimeString(
                        "tr-TR",
                        (options = {
                          hour: "numeric",
                          minute: "2-digit",
                        })
                      )
                    }}</b-badge
                  >
                </b-list-group-item>
              </b-list-group>
            </b-row>
            <b-row v-if="closed_conversations.length > 0" class="mx-0">
              <h4 class="text-primary m-1">
                Pasif Konuşmalar
                <b-button
                  class="btn-icon"
                  @click="
                    show_passive_conversations = !show_passive_conversations
                  "
                  variant="flat-primary"
                >
                  <feather-icon
                    :icon="
                      !show_passive_conversations ? 'EyeIcon' : 'EyeOffIcon'
                    "
                  ></feather-icon
                ></b-button>
              </h4>
            </b-row>
            <b-row v-if="show_passive_conversations" class="mx-0">
              <b-list-group class="w-100 mx-1">
                <b-list-group-item
                  v-for="(item, i) in closed_conversations.filter(
                    (e) => e.status == 'closed'
                  )"
                  class="border-0 rounded my-25 d-flex justify-content-between align-items-center"
                  href="#some-link"
                  :key="i"
                  @click="get_conversation_details(item.id)"
                  :variant="
                    conversation &&
                    conversation.id &&
                    conversation.id === item.id
                      ? 'primary'
                      : ''
                  "
                >
                  <b-media no-body>
                    <b-media-aside class="mr-1">
                      <b-avatar
                        :class="
                          conversation &&
                          conversation.id &&
                          conversation.id === item.id
                            ? 'badge-glow'
                            : ''
                        "
                        :badge="
                          conversation &&
                          conversation.id &&
                          conversation.id === item.id
                            ? ''
                            : item.messages
                                .filter((e) => e.seen_at == null)
                                .length.toString()
                        "
                        :badge-variant="
                          conversation &&
                          conversation.id &&
                          conversation.id === item.id
                            ? ''
                            : 'success'
                        "
                        :variant="
                          conversation &&
                          conversation.id &&
                          conversation.id === item.id
                            ? 'success'
                            : 'light-success'
                        "
                      >
                        {{ item.customer.title.slice(0, 1).toUpperCase() }}
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h5 class="font-weight-bolder mb-0">
                        {{ item.customer.title }}
                      </h5>
                      <b-card-text class="font-small-4 mb-0">
                        <small
                          style="
                            display: inline-block;
                            width: 10vw;
                            white-space: nowrap;
                            overflow: hidden !important;
                            text-overflow: ellipsis;
                          "
                          >{{
                            item.messages[item.messages.length - 1].body
                          }}</small
                        >
                      </b-card-text>
                      <b-card-text class="font-small-4 mb-0">
                        <b-row class="mx-0">
                          <b-badge class="mr-25" variant="success" size="sm">{{
                            item.source
                          }}</b-badge>
                        </b-row>
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                  <b-badge
                    :variant="
                      conversation &&
                      conversation.id &&
                      conversation.id === item.id
                        ? 'secondary'
                        : 'light-secondary'
                    "
                    >{{
                      new Date(item.last_activity_at).toLocaleTimeString(
                        "tr-TR",
                        (options = {
                          hour: "numeric",
                          minute: "2-digit",
                        })
                      )
                    }}</b-badge
                  >
                </b-list-group-item>
              </b-list-group>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <!-- text-center font-weight-bolder form-control-lg round -->
      <b-col cols="8" class="px-0">
        <b-card
          no-body
          class="mb-0 bg-transparent border-bottom"
          style="height: 50vh; overflow-y: auto"
        >
          <div>
            <b-row class="border-bottom mx-0">
              <b-col>
                <b-row align-h="between" align-v="center">
                  <b-col v-if="conversation">
                    <b-avatar size="38" variant="light-success" class="my-1">
                      {{
                        conversation.customer.title.slice(0, 1).toUpperCase()
                      }}
                    </b-avatar>
                    <span class="ml-1 font-weight-bold">{{
                      conversation.customer.title
                    }}</span>
                    <b-badge
                      v-for="(item, i) in conversation.tags"
                      :key="i"
                      class="ml-50"
                      variant="light-primary"
                    >
                      {{ `#${item.name}` }}
                    </b-badge>
                  </b-col>
                  <b-col v-if="conversation">
                    <b-button
                      variant="flat-secondary"
                      class="btn-icon float-right"
                    >
                      <feather-icon icon="MoreVerticalIcon"></feather-icon>
                    </b-button>
                    <!-- <b-button-group class="float-right">
                      <b-button
                        @click="modal_finish_code = true"
                        class="btn-icon mx-25"
                        variant="outline-danger"
                      >
                        <feather-icon icon="TagIcon"></feather-icon>
                      </b-button>
                      <b-button
                        id="popover-reactive-1"
                        ref="button"
                        @click="popoverShow = true"
                        class="btn-icon mx-25"
                        variant="outline-primary"
                      >
                        <feather-icon icon="HashIcon"></feather-icon>
                      </b-button>
                      <b-button
                        id="note-popover-reactive-1"
                        ref="button2"
                        @click="notePopoverShow = true"
                        class="btn-icon mx-25"
                        variant="outline-warning"
                      >
                        <feather-icon icon="FileTextIcon"></feather-icon>
                      </b-button>
                      <b-button
                        class="btn-icon mx-25"
                        variant="outline-success"
                      >
                        <feather-icon icon="FastForwardIcon"></feather-icon>
                      </b-button>
                    </b-button-group> -->
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <!-- <div style="height: 70vh; overflow-y: auto">
            <div ref="spv_msg_div" v-if="conversation" class="messages">
              <div
                v-for="(message, i) in conversation.messages"
                :key="i"
                class="msg"
              >
                <span :class="message.customer_id ? 'msg_from' : 'msg_to'">
                  {{ message.body }}
                  <small class="time">
                    {{
                      new Date(message.created_at).toLocaleTimeString(
                        "tr-TR",
                        (options = {
                          hour: "numeric",
                          minute: "2-digit",
                        })
                      )
                    }}
                  </small>
                </span>
              </div>
            </div>
          </div> -->
          <div style="height: 70vh; overflow-y: auto">
            <div ref="spv_msg_div" v-if="conversation" class="messages">
              <div v-for="(message, i) in conversation.messages" :key="i">
                <div v-if="isNewDay(conversation.messages, i)">
                  <div class="date-line">
                    <small class="line"></small>
                    <b-badge pill variant="light-secondary">{{
                      formatDate(message.created_at)
                    }}</b-badge>
                    <small class="line"></small>
                  </div>
                </div>

                <b-row class="px-1">
                  <b-col>
                    <span
                      v-if="message.type != 'note'"
                      :class="message.customer_id ? 'msg_from' : 'msg_to'"
                    >
                      {{ message.body }}
                      <small class="time">
                        {{
                          new Date(message.created_at).toLocaleTimeString(
                            "tr-TR",
                            (options = {
                              hour: "numeric",
                              minute: "2-digit",
                            })
                          )
                        }}
                      </small>
                    </span>
                    <span class="msg_note" v-else>
                      {{ message.body }}
                      <small class="time">
                        {{
                          new Date(message.created_at).toLocaleTimeString(
                            "tr-TR",
                            (options = {
                              hour: "numeric",
                              minute: "2-digit",
                            })
                          )
                        }}
                      </small>
                    </span>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
          <b-card-footer class="border-0">
            <b-row v-if="conversation" class="mx-0">
              <b-button-group class="w-100">
                <b-form-input
                  @keyup.enter="send_message('reply')"
                  ref="inputRef"
                  v-model="message"
                  :placeholder="lang('t_yourMessage')"
                />
                <b-button
                  @click="send_message('reply')"
                  variant="outline-primary"
                  class="btn-icon"
                >
                  <feather-icon icon="SendIcon"></feather-icon>
                </b-button>
              </b-button-group>
            </b-row>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      :title="lang('t_finishCode')"
      static
      centered
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      @ok="save_finish_code"
      v-model="modal_finish_code"
      @show="resetFinishCode"
    >
      <!-- <template #modal-title>
        <code>{{ lang("t_remainingTime") }} : {{ acw_timer }}</code>
      </template> -->
      <b-form-group>
        <v-select
          v-model="interaction_finish_code"
          :options="finish_codes"
          :reduce="(val) => val"
          :placeholder="lang('t_finishCode')"
          label="finish_code"
        >
        </v-select>
      </b-form-group>

      <b-form-group>
        <b-form-textarea
          v-model="interaction_note"
          :placeholder="lang('t_note')"
          rows="5"
        >
        </b-form-textarea>
      </b-form-group>
    </b-modal>
    <b-popover
      class="border-0"
      ref="popover"
      target="popover-reactive-1"
      triggers="click"
      :show.sync="popoverShow"
      placement="auto"
      container="my-container"
      @show="onShow"
    >
      <template v-slot:title>
        <div
          style="width: 400px"
          class="d-flex justify-content-between align-items-center"
        >
          <span>Etiket</span>
          <b-button
            class="close"
            variant="transparent"
            aria-label="Close"
            @click="onClose"
          >
            <span class="d-inline-block text-white" aria-hidden="true"
              >&times;</span
            >
          </b-button>
        </div>
      </template>

      <div v-if="conversation" style="min-height: 10vh">
        <b-form-group class="mb-1">
          <v-select
            multiple
            :reduce="(val) => val"
            v-model="conversation.tags"
            :options="tags"
            label="name"
          >
          </v-select>
        </b-form-group>

        <b-button
          class="mt-50 float-right"
          size="sm"
          variant="primary"
          @click="saveTags"
        >
          Ok
        </b-button>
        <b-button
          size="sm"
          variant="danger"
          class="mr-1 mt-50 float-right"
          @click="onClose"
        >
          Cancel
        </b-button>
      </div>
    </b-popover>
    <b-popover
      class="border-0"
      ref="note_popover"
      target="note-popover-reactive-1"
      triggers="click"
      :show.sync="notePopoverShow"
      placement="auto"
      container="my-container"
      @show="onShow"
    >
      <template v-slot:title>
        <div
          style="width: 400px"
          class="d-flex justify-content-between align-items-center"
        >
          <span>Not Ekle</span>
          <b-button
            class="close"
            variant="transparent"
            aria-label="Close"
            @click="onClose"
          >
            <span class="d-inline-block text-white" aria-hidden="true"
              >&times;</span
            >
          </b-button>
        </div>
      </template>

      <div v-if="conversation">
        <b-form-group class="mb-1">
          <b-form-textarea
            v-model="note"
            rows="3"
            :placeholder="lang('t_note')"
          ></b-form-textarea>
        </b-form-group>

        <b-button
          class="mb-1 mt-50 float-right"
          size="sm"
          variant="primary"
          @click="send_message('note')"
        >
          Ok
        </b-button>
        <b-button
          size="sm"
          variant="danger"
          class="mb-1 mr-1 mt-50 float-right"
          @click="onClose"
        >
          Cancel
        </b-button>
      </div>
    </b-popover>
  </div>
</template>

<script>
import {
  BForm, BListGroup, BListGroupItem, BMediaAside, BMediaBody, BCardFooter,
  BFormGroup, BButtonGroup,
  BFormInvalidFeedback,
  BFormCheckbox, BCardText,
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal, BFormTextarea, BPopover
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BPopover,
    BCardText,
    BMediaAside, BMediaBody, BCardFooter,
    BListGroup, BListGroupItem,
    BButtonGroup,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BFormTextarea
  },

  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      finish_codes: [],
      conversations: [],
      closed_conversations: [],
      show_passive_conversations: false,
      tags: [{ name: 'Kültür' }, { name: 'BilgiPaylaşımı' }, { name: 'Gezi' }],
      conversation: null,
      searchQuery: '',
      message: '',
      modal_finish_code: false,
      interaction_finish_code: '',
      interaction_note: '',
      popoverShow: false,
      notePopoverShow: false,
      note: ''

    }
  },
  mounted: async function () {
    globalThis.spv_external_chat = this;
    await this.set_conf();
  },
  methods: {
    async set_conf() {
      try {
        this.$http_in.defaults.headers.common['text-channel-authorization'] = globalThis.chat_user.text_channel_token;
        this.$http_in.defaults.headers.common['text-channel-user'] = globalThis.chat_user.text_channel_user_id;
        this.finish_codes = globalThis.finish_codes.filter(e => e.is_product_finish_code);
        // globalThis.external_chat = this;
        await this.get_conversations();
      } catch (error) {

      }

    },
    resetFinishCode() {
      this.interaction_finish_code = '';
      this.interaction_note = '';
    },
    focusRef(ref) {
      this.$nextTick(() => {
        this.$nextTick(() => {
          (ref.$el || ref).focus()
        })
      })
    },
    onClose() {
      this.popoverShow = false;
      this.notePopoverShow = false;
    },
    async saveTags() {
      await this.$http_in.put(`/text-channel/v1/SetConversationTags/${this.conversation.id}`, {
        tags: this.conversation.tags
      });
      this.popoverShow = false;
    },

    onShow() {
      this.note = '';
    },
    save_finish_code(bvModalEvent) {
      if (this.interaction_finish_code == '') {

        this.$bvToast.toast(`Sonuç kodu boş bırakılamaz...`, {
          title: `Uyarı`,
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: false,
          variant: 'warning',
        })
        bvModalEvent.preventDefault();
        return;
      }
      // clearInterval(globalThis.LayoutContentRendererDefault.acw_interval);

      globalThis._voyce.$qm.emit('qmgr:FinishInteraction', {
        queue: this.conversation.customer.metadata.queue,
        interactionId: this.conversation.id,
      });

      globalThis._voyce.$qm.emit('qmgr:FinalizeInteraction', {
        queue: this.conversation.customer.metadata.queue,
        interactionId: this.conversation.id,
        verdict: this.interaction_finish_code.finish_code,
        details: {
          note: this.interaction_note,
          finish_code: { ...this.interaction_finish_code }
        },
      });

      this.close_conversation();
    },

    isNewDay(messages, index) {
      if (index === 0) {
        return true; // İlk mesaj her zaman yeni bir gün olarak kabul edilir
      } else {
        const currentMessage = new Date(messages[index].created_at);
        const previousMessage = new Date(messages[index - 1].created_at);
        return (
          currentMessage.getDate() !== previousMessage.getDate() ||
          currentMessage.getMonth() !== previousMessage.getMonth() ||
          currentMessage.getFullYear() !== previousMessage.getFullYear()
        );
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString("tr-TR", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    formatDateString(date) {
      const d = new Date(date);
      const year = d.getFullYear();
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const day = String(d.getDate()).padStart(2, '0');
      const hours = String(d.getHours()).padStart(2, '0');
      const minutes = String(d.getMinutes()).padStart(2, '0');
      const seconds = String(d.getSeconds()).padStart(2, '0');

      const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
      return formattedDate;
    },

    scrollToBottom: () => {
      var scr = globalThis.spv_external_chat.$refs.spv_msg_div;
      if (![null, undefined].includes(scr)) {
        scr.scrollTop = scr.scrollHeight;
      }
    },
    confirmText(item) {
      this.currentData = item;
      console.log("confirmText", item);
      this.$swal({
        title: this.lang('t_deleteRecord'),
        text: this.lang('t_areYouSureYouWantToDeleteTheSelectedRecord'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.lang('t_yes'),
        cancelButtonText: this.lang('t_cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',

        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.delete_record();
        }
      })
    },
    close_conversation: async function () {
      var response = (await this.$http_in.put(`/text-channel/v1/Conversation/${this.conversation.id}`)).data;

      await this.get_conversations();
      this.conversation = null;
    },
    get_conversations: async function () {
      var response = (await this.$http_in.get(`/text-channel/v1/Conversation`)).data;
      for (const item of response.data) {
        item.messages = item.messages.sort((a, b) => {
          const dateA = new Date(a.created_at);
          const dateB = new Date(b.created_at);
          return dateA - dateB;
        });
      }
      this.conversations = response.data;
      var today = new Date().toISOString().slice(0, 10);

      this.closed_conversations = this.conversations.filter(function (conversation) {
        var closedAt = new Date(conversation.closed_at).toISOString().slice(0, 10);
        return closedAt === today;
      });

      console.log("conversations", response);

    },
    get_conversation_details: async function (id) {
      var response = (await this.$http_in.get(`/text-channel/v1/ConversationDetails/${id}`)).data;
      this.conversation = response.data;
      this.conversation.messages = this.conversation.messages.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateA - dateB;
      });
      setTimeout(() => {
        this.scrollToBottom();

      }, 100);
      this.$refs.inputRef.focus();

      console.log("conversation", response);
    },
    send_message: async function (type) {
      var response = (await this.$http_in.post(`/text-channel/v1/SendMessage`, {
        message: type == 'reply' ? this.message : this.note,
        conversation_id: this.conversation.id,
        type
      })).data;
      let _conversation = this.conversations.find(e => e.id == this.conversation.id);
      if (_conversation) {
        _conversation.last_activity_at = this.formatDateString(new Date());
        _conversation.messages.push({
          "account_id": "",
          "attachments": [],
          "body": type == 'reply' ? this.message : this.note,
          "content_type": "text",
          "conversation_id": this.conversation.id,
          "created_at": this.formatDateString(new Date()),
          "customer_id": null,
          "metadata": null,
          "object": "message",
          "private": false,
          "seen_at": "2023-05-17T11:36:45Z",
          "sent_at": null,
          "source": "chat",
          "type": type,
        });
      }
      this.message = '';
      this.get_conversation_details(this.conversation.id);
      this.$refs.inputRef.focus();
      this.notePopoverShow = false;
    },



  },

}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.date-line {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.line {
  flex-grow: 1;
  height: 0px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 0 10px;
}

.date {
  padding: 0 10px;
  font-weight: bold;
  z-index: 1;
  background-color: #fff;
  position: relative;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
